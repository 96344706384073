.document-invest {
  height: 85px;
}

.image-div {
  min-width: 160px;
}

.image-div-document {
  width: 200px;
}

.document-invest>div {
  padding: 25px;
}

.document-btn {
  width: 100px;
}

.document-title-div {
  padding-top: 20px;
}

.portofolio-document-tile {
  font-size: 18px !important;
}

.invested-div {
  width: 200px;
  height: 70px;
}

.invested {
  font-size: 25px !important;
}

.asset-summary {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.investmentIn{
  display: block;
}

@media (max-width: 768px) {
  .document-invest {
    height: 170px;
  }

  .image-div {
    width: 140px;
  }

  .document-invest>div {
    padding: 20px;
  }

  .document-btn {
    width: 55%;
  }

  .document-title {
    font-size: 10px !important;
  }

  .document-title-div {
    padding-bottom: 10px;
  }

  .invested-div {
    width: 120px;
    height: 45px;
  }

  .invested {
    font-size: 13px !important;
  }
}

/* @media (min-width: 769px) and (max-width: 999px) {
  .document-invest {
    height: 190px;
  }

  .document-invest>div {
    padding: 20px;
  }

  .image-div {
    width: 190px;
  }

  .document-title {
    font-size: 1rem !important;
  }

  .document-title-div {
    padding-bottom: 15px;
  }

  .document-description {
    font-size: 0.65rem !important;
  }

  .invested-div {
    width: 80px;
    height: 50px;
  }

  .invested {
    font-size: 15px !important;
  }

  .document-btn {
    width: 28%;
  }
} */

/* @media (min-width: 1000px) and (max-width: 1630px) {
  .document-invest {
    height: 105px;
  }

  .image-div {
    width: 220px;
  }

  .document-title {
    font-size: 1.2rem !important;
  }

  .document-title-div {
    padding-top: 45px;
  }

  .document-description {
    font-size: 0.75rem !important;
  }

  .document-btn {
    width: 22%;
  }

  .invested {
    font-size: 20px !important;
  }

  .invested-div {
    margin-right: 10px;
  }
} */

/* @media (min-width: 1025px) and (max-width: 1366px) {
  .document-title {
    font-size: 1.2rem !important;
  }

  .document-description {
    font-size: 0.95rem !important;
  }
} */