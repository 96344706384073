
a {
  text-decoration: none;
  color: inherit;
}

div#registration-container {
  margin: auto;
}

*.radio-container {
  width: 70%;
}

*.registration-page-body {
  width: 75%;
  margin: 35px auto !important;
  font-size: 20px !important;
  font-weight: 300 !important;
}

#registration-identity-column-left,
#registration-identity-column-right {
  width: 45%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  div#registration-container {
    margin: auto;
    width: 80%;
  }
  *.radio-container {
    width: 100% !important;
    margin: 10px auto !important;
  }

  *.registration-page-body {
    width: 100%;
    margin: 35px auto !important;
    font-size: 20px !important;
    font-weight: 300 !important;
  }

  *.radio-container,
  *.form-container {
    width: 100% !important;
    margin: 10px auto !important;
  }

  #registration-identity-column-left,
  #registration-identity-column-right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
