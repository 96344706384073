@media (min-width: 601px) and (max-width: 768px) {
.m-left-50-tablet {
  margin-left: 50px;
}

.display-flex-tablet{
  display: flex;
}

}

@media (min-width: 769px) and (max-width: 1366px) {
  .column-tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row-tablet {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .wrap-tablet {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .start-tablet {
    display: flex;
    align-items: flex-start;
  }

  .display-none-tablet {
    display: none !important;
  }

  .width-100-tablet {
    width: 100%;
  }

  .width-80-tablet {
    width: 80%;
  }

  .width-70-tablet {
    width: 70%;
  }

  .width-50-tablet {
    width: 50%;
  }

  .width-40-tablet {
    width: 40%;
  }

  .width-30-tablet {
    width: 30%;
  }

  .m-bottom-35-tablet {
    margin-bottom: 35px;
  }

  .m-top-35-tablet {
    margin-top: 35px;
  }

  .m-right-35-tablet {
    margin-right: 35px;
  }

  .m-left-35-tablet {
    margin-left: 35px;
  }

  .m-bottom-15-tablet {
    margin-bottom: 15px;
  }

  .m-top-15-tablet {
    margin-top: 15px;
  }

  .m-right-15-tablet {
    margin-right: 15px;
  }

  .m-left-15-tablet {
    margin-left: 15px;
  }

  .m-0-tablet {
    margin: 0;
  }

  .font-size-mobile-1 {
    font-size: 2rem !important;
  }

  .font-size-mobile-2 {
    font-size: 1.75rem !important;
  }

  .font-size-mobile-3 {
    font-size: 1.5rem !important;
  }

  .font-size-mobile-4 {
    font-size: 1.25rem !important;
  }

  .font-size-mobile-5 {
    font-size: 1rem !important;
  }
}

@media (min-width: 1367px) and (max-width: 1688px) {

  .width-80-mid {
    width: 80%;
  }

  .width-75-mid {
    width: 75%;
  }

  .width-65-mid {
    width: 65%;
  }

  .width-55-mid {
    width: 55%;
  }

  .width-45-mid {
    width: 45%;
  }

  .width-35-mid {
    width: 35%;
  }

  .column-mid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .m-0-mid {
    margin: 0;
  }

}