.page-title {
  font-size: 2.5rem !important;
}

.page-subtitle {
  font-size: 1.25rem !important;
}

@media (max-width: 768px) {
  .page-title {
    font-size: 1.75rem !important;
  }

  .page-subtitle {
    font-size: 0.85rem !important;
  }
}
