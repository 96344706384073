.shine-effect {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.shine-effect:after {

    animation: shine 3s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.89) 0%,
            rgba(255, 255, 255, 0.596) 77%,
            rgba(255, 255, 255, 0.829) 92%,
            rgba(255, 255, 255, 0.623) 100%);
}

/* Active state */

.shine-effect:active:after {
    opacity: 0;
}

@keyframes shine {
    10% {
        opacity: 1;
        top: 50%;
        left: 100%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }

    100% {
        opacity: 0;
        top: 50%;
        left: 100%;
        transition-property: left, top, opacity;
    }
}