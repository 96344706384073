@media (max-width: 768px) {
  .labeled-figure-value {
    font-size: 1.5em !important;
  }

  .labeled-figure-description {
    font-size: 0.55em !important;
  }
}

@media (min-width: 769px) and (max-width: 1040px) {
  .labeled-figure-value {
    /* font-size: 1.25em !important; */
  }

  .labeled-figure-description {
    font-size: 0.55em !important;
  }
}

@media (min-width: 1041px) and (max-width: 1366px) {
  .labeled-figure-value {
    /* font-size: 1.45em !important; */
  }

  .labeled-figure-description {
    font-size: 0.60em !important;
  }
}
