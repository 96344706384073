.card-background {
  min-width: 220px;
  height: 300px;
  box-shadow: 0 1px 15px #b5b5b5;
  z-index: -2;
}

.acc-card-chip {
  top: 30px;
  left: 33%;
  position: relative;
  width: 75px;
  height: 16px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 1px 3px gray inset;
  z-index: -1;
}

.acc-card-text {
  top: 260px;
  left: 30px;
  position: relative;
  z-index: -1;
}

@media (max-width: 768px) { 

  .card-background {
    width: 100%;
    height: 240px;
  }

  .acc-card-chip {
    top: 35%;
    left: 30px;
    position: relative;
    width: 15px;
    height: 70px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 1px 3px gray inset;
  }

  .acc-card-text {
    bottom: -140px;
    left: 30px;
    position: relative;
    width: 50%;
  }
}