.investment-img {
    height: 340px;
    width: 300px;
    box-shadow: 0 1px 15px #b5b5b5;
  }

  .vertical-text {
    bottom: -280px;
    left: 30px;
    position: relative;
  }

@media (max-width: 768px) {

    .investment-img {
      height: 220px;
      width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1366px) {
  .investment-img {
    height: 260px;
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
    box-shadow: 0 1px 15px #b5b5b5;
  }

  .vertical-text {
    bottom: -150px;
    left: 30px;
    position: relative;
  }

}