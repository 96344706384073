body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");

@font-face {
  font-family: "Gilroy";
  font-weight: lighter;
  /* src: local('Gilroy'), url(./assets/fonts/gilroy-extrabold.otf) format('opentype'); */
  src: local("Gilroy"), url(../assets/fonts/gilroy-light.woff) format("woff");
  /* src: local('Gilroy'), url(./assets/fonts/gilroy-extrabold.ttf) format('ttf'); */
  /* src: local('Gilroy'), url(./assets/fonts/gilroy-extrabold.eot) format('eot'); */
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.display-none {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

body {
  font-family: "Roboto", sans-serif;
}

.blurred>* {
  pointer-events: none;
  overflow: hidden;
  filter: blur(5px) brightness(0.9);
}

.acc-text {
  font-family: "Gilroy", sans-serif !important;
  font-weight: 300 !important;
}

.acc-blue {
  color: #223e60;
}

.acc-darkblue {
  color: #05254b !important;
}

.acc-green {
  color: #1bd0ae;
}

.acc-lightgreen {
  color: #00eec1 !important;
}

.acc-darkgray {
  color: #404040;
}

.acc-lightgray {
  color: #919197;
}

.acc-white {
  color: #fff;
}

.acc-red {
  color: #ff8585;
}

.ultralight {
  font-weight: 100 !important;
}

.light {
  font-weight: 300 !important;
}

.bold {
  font-weight: 600 !important;
}

.row {
  display: flex;
  flex-direction: row;
}


.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.column-reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.start {
  align-items: flex-start;
}

.end {
  align-items: flex-end;
}

.center {
  align-items: center;
}

.stretch {
  justify-content: space-between;
}

.roomstretch {
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}

.m-left-10 {
  margin-left: 10px !important;
}

.m-right-10 {
  margin-right: 10px !important;
}

.m-top-10 {
  margin-top: 10px !important;
}

.m-bottom-10 {
  margin-bottom: 10px !important;
}

.m-left-15 {
  margin-left: 15px !important;
}

.m-right-15 {
  margin-right: 15px !important;
}

.m-top-15 {
  margin-top: 15px !important;
}

.m-bottom-15 {
  margin-bottom: 15px !important;
}

.m-left-20 {
  margin-left: 20px !important;
}

.m-right-20 {
  margin-right: 20px !important;
}

.m-top-20 {
  margin-top: 20px !important;
}

.m-bottom-20 {
  margin-bottom: 20px !important;
}
.m-left-30 {
  margin-left: 30px !important;
}

.m-right-30 {
  margin-right: 30px !important;
}

.m-top-30 {
  margin-top: 30px !important;
}

.m-bottom-30 {
  margin-bottom: 30px !important;
}

.m-left-50 {
  margin-left: 50px !important;
}

.m-right-50 {
  margin-right: 50px !important;
}

.m-top-50 {
  margin-top: 50px !important;
}

.m-bottom-50 {
  margin-bottom: 50px !important;
}

.m-left-75 {
  margin-left: 75px !important;
}

.m-right-75 {
  margin-right: 75px !important;
}

.m-top-75 {
  margin-top: 75px !important;
}

.m-bottom-75 {
  margin-bottom: 75px !important;
}

.m-left-100 {
  margin-left: 100px !important;
}

.m-right-100 {
  margin-right: 100px !important;
}

.m-top-100 {
  margin-top: 100px !important;
}

.m-bottom-100 {
  margin-bottom: 100px !important;
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-65 {
  width: 65%;
}

.width-60 {
  width: 60%;
}

.width-55 {
  width: 55%;
}

.width-50 {
  width: 50% !important;
}

.width-45 {
  width: 45%;
}

.width-40 {
  width: 40%;
}

.width-35 {
  width: 35%;
}

.width-30 {
  width: 30%;
}

.width-25 {
  width: 25%;
}

.width-20 {
  width: 20%;
}

.width-15 {
  width: 15%;
}

.bg-no-repeat {
  background-repeat: round !important;
}

.vertical-investment {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.blue-button {
  background: transparent linear-gradient(270deg, #194374 0%, #05254b 100%) 0% 0% no-repeat padding-box;
}

.blue-button:hover {
  background: transparent linear-gradient(270deg, #05254b 0%, #194374 100%) 0% 0% no-repeat padding-box;
}

.green-button {
  background: transparent linear-gradient(270deg, #1cd0ae 0%, #00eec1 100%) 0% 0% no-repeat padding-box;
}

.green-button:hover {
  background: transparent linear-gradient(270deg, #00eec1 0%, #1cd0ae 100%) 0% 0% no-repeat padding-box;
}

.delete-button {
  background: white;
  border: "1px solid #ff8585";
  color: "#ff8585";
}

.no-pointer {
  background: white !important;
  color: #05254b !important;
  pointer-events: none;
  border: 1px solid #1cd0ae !important;
}

.white-button {
  background: white !important;
  color: #05254b !important;
  border: 1px solid #1bd0ae;
}

.implicit-link {
  cursor: pointer;
}

.anchor-element {
  position: relative;
}

.underlined-link {
  text-decoration: underline;
  cursor: pointer;
}

.condensed-lineheight {
  line-height: 1.25 !important;
}

:root {
  --toastify-icon-color-success: #1bd0ae !important;
  --toastify-color-progress-success: #1bd0ae !important;
  --toastify-icon-color-error: #ff8585 !important;
  --toastify-color-progress-error: #ff8585 !important;
  --toastify-icon-color-info: #05254b !important;
  --toastify-color-progress-info: #05254b !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(60%) sepia(37%) saturate(802%) hue-rotate(119deg) brightness(101%) contrast(98%);
  cursor: pointer;
}

/* UNIVERSAL APP COMPONENTS ONLY BELOW */
#router-container {
  display: flex;
}
