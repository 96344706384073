.asset-image {
  width: 240px;
  height: 300px;
  box-shadow: 0 1px 15px #b5b5b5;
  z-index: -2;
}

.asset-chip {
  top: 30px;
  left: 35%;
  position: relative;
  width: 70px;
  height: 15px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 1px 3px gray inset;
  z-index: -1;
}

.asset-text {
  bottom: -240px;
  left: 30px;
  position: relative;
  z-index: -1;
}

.asset-summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projection-description {
  font-size: 0.7rem !important;
}

.details-box {
  width: 55%;
  height: 315px;
  border-radius: 16px;
  padding: 25px;
}

.timer-box {
  border-radius: 16px;
  padding: 25px;
  height: 315px;
  width: 340px;
}

@media (min-width: 1367px) and (max-width: 1688px) {
  .asset-details {
    font-size: 0.7rem !important;
  }

  .projection-details {
    font-size: 1.3rem !important;
  }

  .asset-title {
    font-size: 1.3rem !important;
  }

  /* .asset-details {
      font-size: 0.7rem !important;
  } */

  .projection-details {
      font-size: 1.3rem !important;
  }

  .asset-title {
      font-size: 1.3rem !important;
  }

  .asset-chip {
      top: 30px;
      left: 32%;
      position: relative;
      width: 70px;
      height: 15px;
      border-radius: 16px;
      background-color: white;
  }

}

@media (min-width: 769px) and (max-width: 1366px) {
  .asset-imgage {
    width: 250px;
    height: 300px;
  }

  .asset-details {
    font-size: 0.6rem !important;
  }

  .projection-details {
    font-size: 1rem !important;
  }

  .asset-title {
    font-size: 1.3rem !important;
  }

  .projection-description {
    font-size: 0.5rem;
  }

  .details-box {
    width: 60%;
    height: 300px;
    border-radius: 16px;
    padding: 25px;
  }

  .asset-text {
    bottom: -240px;
    left: 30px;
    position: relative;
  }

  .timer-box {
    border-radius: 16px;
    padding: 25px;
    height: 300px;
    width: 330px;
  }
}
