.opp-details-img {
  width: 350px;
  height: 360px;
  margin-right: 35px;
  box-shadow: 0 1px 15px #b5b5b5;
}

.vimeo-video {
  width: 100%;
  height: 550px;
}

.chip {
  top: 30px;
  left: 35%;
  position: relative;
  width: 70px;
  height: 15px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 1px 3px gray inset;
  z-index: -1;
}

.text {
  bottom: -300px;
  left: 30px;
  position: relative;
  z-index: -1;
}

@media (max-width: 768px) {
  .opp-details-img {
    width: 100%;
    height: 220px;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .vimeo-video {
    width: 100%;
    height: 250px;
  }

  .chip {
    top: 35%;
    left: 30px;
    position: relative;
    width: 15px;
    height: 70px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 1px 3px gray inset;
  }

  .text {
    bottom: -110px;
    left: 30px;
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .opp-details-img {
    width: 100%;
    height: 240px;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .vimeo-video {
    width: 100%;
    height: 300px;
  }

  .chip {
    top: 35%;
    left: 30px;
    position: relative;
    width: 15px;
    height: 70px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 1px 3px gray inset;
  }

  .text {
    bottom: -130px;
    left: 30px;
    position: relative;
  }
}
