.portfolio-invest {
  height: 245px;
}

.image-div {
  min-width: 160px;
}

.image-div-dashboard {
  width: 150px;
}

.portfolio-invest>div {
  padding: 25px;
}

.portfolio-btn {
  width: 100px;
}

.portfolio-title-div {
  padding-bottom: 25px;
}

.invested-div {
  width: 200px;
  height: 70px;
}

.invested {
  font-size: 25px !important;
}

.asset-summary {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.investmentIn{
  display: block;
}

@media (max-width: 768px) {
  .portfolio-invest {
    height: 170px;
  }

  .image-div {
    width: 140px;
  }

  .portfolio-invest>div {
    padding: 20px;
  }

  .portfolio-btn {
    width: 55%;
  }

  .portfolio-title {
    font-size: 0.85rem !important;
  }

  .portfolio-title-div {
    padding-bottom: 10px;
  }

  .invested-div {
    width: 120px;
    height: 45px;
  }

  .invested {
    font-size: 13px !important;
  }
}

@media (min-width: 769px) and (max-width: 999px) {
  .portfolio-invest {
    height: 190px;
  }

  .portfolio-invest>div {
    padding: 20px;
  }

  .image-div {
    width: 190px;
  }

  .portfolio-title {
    font-size: 1rem !important;
  }

  .portfolio-title-div {
    padding-bottom: 15px;
  }

  .portfolio-description {
    font-size: 0.65rem !important;
  }

  .invested-div {
    width: 80px;
    height: 50px;
  }

  .invested {
    font-size: 15px !important;
  }

  .portfolio-btn {
    width: 28%;
  }
}

@media (min-width: 1000px) and (max-width: 1630px) {
  .portfolio-invest {
    height: 220px;
  }

  .image-div {
    width: 220px;
  }

  .portfolio-title {
    font-size: 1.1rem !important;
  }

  .portfolio-title-div {
    padding-bottom: 15px;
  }

  .portfolio-description {
    font-size: 0.75rem !important;
  }

  .portfolio-btn {
    width: 22%;
  }

  .invested {
    font-size: 20px !important;
  }

  .invested-div {
    margin-right: 10px;
  }
}

/* @media (min-width: 1025px) and (max-width: 1366px) {
  .portfolio-title {
    font-size: 1.2rem !important;
  }

  .portfolio-description {
    font-size: 0.95rem !important;
  }
} */