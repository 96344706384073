@media (max-width: 768px) {

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    overflow-y: hidden;
  }

  .mobile-filters {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: white;
  }
  
  .column-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column-reverse-mobile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .row-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .display-none-mobile {
    display: none !important;
  }

  .stretch-mobile {
    justify-content: space-between;
  }

  .start-column {
    justify-content: flex-start;
  }

  .end-column {
    justify-content: flex-end;
  }

  .start-row {
    align-items: flex-start;
  }

  .width-100-mobile {
    width: 100% !important;
  }

  .width-90-mobile {
    width: 90% !important;
  }

  .width-80-mobile {
    width: 80%;
  }

  .width-60-mobile {
    width: 60%;
  }

  .width-50-mobile {
    width: 50%;
  }

  .width-30-mobile {
    width: 30%;
  }

  .width-20-mobile {
    width: 20%;
  }

  .m-left-100-mobile {
    margin-left: 100px;
  }

  .m-right-100-mobile {
    margin-right: 100px;
  }

  .m-top-100-mobile {
    margin-top: 100px;
  }

  .m-top-125-mobile {
    margin-top: 125px;
  }

  .m-bottom-100-mobile {
    margin-bottom: 100px;
  }

  .m-top-50-mobile {
    margin-top: 50px;
  }

  .m-bottom-50-mobile {
    margin-bottom: 50px;
  }

  .m-right-50-mobile {
    margin-right: 50px;
  }

  .m-left-50-mobile {
    margin-left: 50px;
  }

  .m-top-30-mobile {
    margin-top: 30px !important;
  }

  .m-bottom-30-mobile {
    margin-bottom: 30px;
  }

  .m-right-30-mobile {
    margin-right: 30px;
  }

  .m-left-30-mobile {
    margin-left: 30px;
  }

  .m-top-15-mobile {
    margin-top: 15px !important;
  }

  .m-bottom-15-mobile {
    margin-bottom: 15px !important;
  }

  .m-right-15-mobile {
    margin-right: 15px;
  }

  .m-left-15-mobile {
    margin-left: 15px !important;
  }

  .m-top-10-mobile {
    margin-top: 10px !important;
  }

  .m-bottom-10-mobile {
    margin-bottom: 10px;
  }

  .m-right-10-mobile {
    margin-right: 10px;
  }

  .m-left-10-mobile {
    margin-left: 10px !important;
  }

  .m-right-0-mobile {
    margin-right: 0px !important;
  }

  .m-left-0-mobile {
    margin-left: 0px !important;
  }

  .m-0-mobile {
    margin: 0 !important;
  }

  .font-size-mobile-1 {
    font-size: 1.75rem !important;
  }

  .font-size-mobile-2 {
    font-size: 1.5rem !important;
  }

  .font-size-mobile-3 {
    font-size: 1.25rem !important;
  }

  .font-size-mobile-4 {
    font-size: 1rem !important;
  }

  .font-size-mobile-5 {
    font-size: 0.75rem !important;
  }

  .font-size-mobile-6 {
    font-size: 0.5rem !important;
  }

  .text-align-center {
    text-align: center;
  }
  /* UNIVERSAL APP COMPONENTS ONLY BELOW */
  #router-container {
    display: inherit;
  }
}
